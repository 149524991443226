* {
    scroll-behavior: smooth;
}
html {
    box-sizing: border-box;
    font-size: 62.5%;
}

body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    font-size: 1.6rem;
    height: 100%;
}

body::-webkit-scrollbar {
    width: 1rem;
    background-color: #212121;
}

body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
    border-radius: 2rem;

    background-color: #277a35;
}

img {
    display: block;
    max-width: 100%;
    height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
    margin: 0;
}

p {
    margin: 0;
}

select::-ms-expand {
    display: none;
}

select {
    appearance: none;
    outline: 0;
    box-shadow: none;
    height: 4rem;
    width: 7rem;
    line-height: 3;
    overflow: hidden;
    border-radius: 0.25em;
    cursor: pointer;
    text-align-last: center;
}

/* .navbar {
    background-color: #060b26;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
} */

.nav-menu.active {
    left: 0;
    transition: 350ms;
}
@media (max-width: 425px) {
    .slick-slide {
        margin-right: 2rem;
        height: 100px;
    }
}
.select {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 50px;
    height: 40px;
    padding: 0;
    list-style: none;
}

.option {
    /* padding: 0 20px 0 10px; */
    min-height: 40px;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;

    pointer-events: none;
    order: 2;
    z-index: 1;
    box-sizing: border-box;
    overflow: hidden;
    white-space: nowrap;
}

.select:focus .option {
    position: relative;
    pointer-events: all;
}

.selectopt {
    opacity: 0;
    position: absolute;
    left: -99999px;
}

.selectopt:checked + .option {
    order: 1;
    z-index: 2;
    border-top: none;
    position: relative;
}
